import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridItem: {
        padding: 7
    },
    cardGrid: {
        padding: "10px 5px",
        marginBottom: "50px"
    }
}));

export default useStyles