import React from "react";

import {
  Typography,
  // Card,
  // CardActions,
  // CardContent,
  // CardMedia,
  Grid,
  Container,
  Button,
  Divider,
  Box,
  Link,
} from "@material-ui/core";

import { DoubleArrow } from "@material-ui/icons";

import useStyles from "./styles";

const Content = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="md" className={classes.cardGrid}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={10} className={classes.gridItem}>
            <Typography variant="h3" align="center" gutterBottom>
              What are we?
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <Box px={3} mb={6} sx={{ letterSpacing: 1 }}>
                Valor Tutors is an initiative taken up by a few Kashmiri College
                students. We started in the quarantine of 2020 and since then,
                we have mentored, educated and guided an upwards of 100 students
                by providing with home tuitions, online and community classes.
                {/*  We aim for the betterment of The Kashmir Valley as a whole */}
              </Box>
            </Typography>
          </Grid>
          <Grid item sm={10} className={classes.gridItem}>
            <Typography variant="h3" align="center" gutterBottom>
              About the Team
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <Box px={3} mb={6} sx={{ letterSpacing: 1 }}>
                Our team is a group of young, dynamic, active and professional
                students affiliated with leading colleges of the Valley and
                outside from the likes of SKIMS, NIT and IIT. We have experience
                with handling both JKBOSE and CBSE curriculum alongwith all
                kinds of competitive entrance exams like NEET, JEE-Main.
                JEE-Advanced, Olympiads, SAT, etc.
              </Box>
            </Typography>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={10} className={classes.gridItem}>
            <Typography variant="h6" align="center" gutterBottom>
              Want to know what our students have to say about us?
            </Typography>
            <Link style={{ textDecoration: "none" }} href="/student/reviews/">
              <Box
                align="center"
                display={"flex"}
                flex={1}
                flexDirection={"row"}
                justifyContent={"center"}
                width={"100%"}
                alignItems="center"
                my={2}
              >
                <Button variant="outlined" color="primary">
                  <Box mx={4} my={1}>
                    <Typography variant="button">
                      Click here to know more
                    </Typography>
                  </Box>
                  <DoubleArrow />
                </Button>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Divider />
    </>
  );
};

export default Content;
