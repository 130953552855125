import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    drawer: {
        // backgroundColor: theme.palette.background.paper
    },
    sidebarCross: {
        padding: "3px 0 12px 0",
        backgroundColor: theme.palette.background.default,
    },
    sideBar: {
        padding: "0 4px 10px 10px",
        backgroundColor: theme.palette.background.default,
    },
    subHeader: {
        backgroundColor: `${theme.palette.background.paper}`,
        border: `solid 0.5px ${theme.palette.text.primary}`,
        borderRadius: 5
    },
    sidebarEnd: {
        marginBottom: "10px",
    }
}));

export default useStyles