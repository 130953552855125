import React from "react";

import { Typography, Grid, Container, Button, Box } from "@material-ui/core";

import useStyles from "./styles";

const Header = ({ setSb }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md">
      <Typography
        variant="h2"
        component="h1"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        Valor Tutors Kashmir
      </Typography>
      <Box px={2}>
        <Typography color="textSecondary" variant="h5" component="h2">
          For the first time in the Valley, we, at Valor Tutors are providing
          hybrid modes of tuitions for students including home tution, online
          classes and community classes along with doubt solving sessions and
          all-day assistance for doubts.
        </Typography>
      </Box>
      <Container maxWidth="sm">
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className={classes.buttons}
        >
          <Grid item xs={12} align="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="/student/new/"
              style={{ textDecoration: "none" }}
            >
              Register to Study
            </Button>
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setSb(true)}
              size="small"
            >
              More Details
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Header;
