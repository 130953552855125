import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    appbar: {
        marginBottom: 70,
        backgroundColor: "#3F51A517",
        color: theme.palette.text.primary,
        backdropFilter: "blur(5px)",
    },
    appbarToolbar: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between"
    },
    appbarMid: {
        display: "flex",
        flexDirection: "row",
        padding: "10px 0",
        fontWeight: "bold",
        alignItems: "center"
    },
    appbarBurger: {
    }
}));

export default useStyles