import React from "react";

import {
  Grid,
  Container,
  Box,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import {
  AlternateEmailRounded,
  WhatsApp,
  LocalPhoneRounded,
  GavelOutlined,
  SecurityOutlined,
  FeedbackOutlined,
} from "@material-ui/icons";

import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="md">
        <Grid container justifyContent="center">
          <Grid item xs={11} sm={9} md={4} className={classes.gridItem}>
            <Box flex={"column"}>
              <List>
                <ListSubheader className={classes.subHeader}>
                  Contact us
                </ListSubheader>
                <ListItem
                  button
                  component="a"
                  href="mailto:hey@valortutors.com"
                  target={"_blank"}
                >
                  <ListItemIcon>
                    <AlternateEmailRounded />
                  </ListItemIcon>
                  <ListItemText secondary="hey@valortutors.com" />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href="https://api.whatsapp.com/send?phone=919796300767"
                  target={"_blank"}
                >
                  <ListItemIcon>
                    <WhatsApp />
                  </ListItemIcon>
                  <ListItemText secondary="9796 300 767" />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href="tel:+919796300767"
                  target={"_blank"}
                >
                  <ListItemIcon>
                    <LocalPhoneRounded />
                  </ListItemIcon>
                  <ListItemText secondary="9796 300 767" />
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid item xs={11} sm={9} md={4} className={classes.gridItem}>
            <Box>
              <List>
                <ListSubheader className={classes.subHeader}>
                  Legal
                </ListSubheader>
                <ListItem
                  button
                  component="a"
                  href="/tnc/"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemIcon>
                    <GavelOutlined />
                  </ListItemIcon>
                  <ListItemText secondary="Terms of Use" />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href="/privacypolicy/"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemIcon>
                    <SecurityOutlined />
                  </ListItemIcon>
                  <ListItemText secondary="Privacy Policy" />
                </ListItem>
                <ListItem
                  button
                  component="a"
                  href="/feedback/"
                  style={{ textDecoration: "none" }}
                >
                  <ListItemIcon>
                    <FeedbackOutlined />
                  </ListItemIcon>
                  <ListItemText secondary="Write feedback" />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
