import React from "react";

import {
  // Typography,
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListSubheader,
  // Link,
} from "@material-ui/core";

import {
  CloseRounded,
  // PersonOutline,
  SchoolRounded,
  QuestionAnswerRounded,
  // ClassSharp,
  InfoOutlined,
  MenuBookRounded,
  MoreHorizRounded,
  HelpOutline,
  AlternateEmailRounded,
  LocalPhoneRounded,
  WhatsApp,
  Instagram,
  FeedbackOutlined,
  SecurityOutlined,
  GavelOutlined,
  DevicesOutlined,
  LinkedIn,
} from "@material-ui/icons";

import useStyles from "./styles";

const MyDrawer = ({ drawer, toggleDrawer }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      open={drawer}
      onClose={toggleDrawer(false)}
      className={classes.drawer}
    >
      <List className={classes.sidebarCross}>
        <ListItem onClick={toggleDrawer(false)}>
          <CloseRounded style={{ fontSize: 28 }} />
        </ListItem>
      </List>
      <Box
        sx={{
          width: 300,
          height: "100%",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        className={classes.sideBar}
      >
        <Divider />
        <List>
          <ListSubheader className={classes.subHeader}>
            For Students and Parents
          </ListSubheader>
          <ListItem
            button
            component="a"
            href="/student/info/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <MoreHorizRounded />
            </ListItemIcon>
            <ListItemText
              primary="Know us better"
              secondary="Click here to find out about our methodologies and experience"
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/student/new/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <SchoolRounded />
            </ListItemIcon>
            <ListItemText
              primary="Register"
              secondary="Start studying with us"
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/student/reviews/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <QuestionAnswerRounded />
            </ListItemIcon>
            <ListItemText
              primary="Reviews"
              secondary="Know what our students have to say about us"
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListSubheader className={classes.subHeader}>
            For Teachers
          </ListSubheader>
          <ListItem
            button
            component="a"
            href="/educator/new/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <MenuBookRounded />
            </ListItemIcon>
            <ListItemText
              primary="Teach with us"
              secondary="Start teaching with us today"
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/educator/founders/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <HelpOutline />
            </ListItemIcon>
            <ListItemText
              primary="The founders"
              secondary="Learn more about the founders of this initiative (not an SnK reference)"
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/educator/info/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <MoreHorizRounded />
            </ListItemIcon>
            <ListItemText
              primary="Know more"
              secondary="Click here to learn about the responsibilities and roles you will be taking up as an educator with Valor Tutors"
            />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListSubheader className={classes.subHeader}>
            Contact us
          </ListSubheader>
          <ListItem
            button
            component="a"
            href="mailto:hey@valortutors.com"
            target={"_blank"}
            rel="noreferrer"
          >
            <ListItemIcon>
              <AlternateEmailRounded />
            </ListItemIcon>
            <ListItemText
              primary="Email"
              secondary="hey@valortutors.com"
            />
          </ListItem>
          <ListItem
            button
            component="a"
            href="tel:+919796300767"
            target={"_blank"}
            rel="noreferrer"
          >
            <ListItemIcon>
              <LocalPhoneRounded />
            </ListItemIcon>
            <ListItemText primary="Phone" secondary="9796 300 767" />
          </ListItem>
          <ListItem
            button
            component={"a"}
            href="https://www.linkedin.com/company/valor-tutors/"
            target={"_blank"}
            rel="noreferrer"
          >
            <ListItemIcon>
              <LinkedIn />
            </ListItemIcon>
            <ListItemText primary="linkedIn" secondary="Valor Tutors" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="https://api.whatsapp.com/send?phone=919796300767"
            target={"_blank"}
            rel="noreferrer"
          >
            <ListItemIcon>
              <WhatsApp />
            </ListItemIcon>
            <ListItemText primary="Whatsapp" secondary="9796 300 767" />
          </ListItem>
          <ListItem
            button
            component={"a"}
            href="https://instagram.com/valortutors"
            target={"_blank"}
            rel="noreferrer"
          >
            <ListItemIcon>
              <Instagram />
            </ListItemIcon>
            <ListItemText primary="Instagram" secondary="@valortutors" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListSubheader className={classes.subHeader}>More</ListSubheader>
          <ListItem
            button
            component="a"
            href="/about/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <InfoOutlined />
            </ListItemIcon>
            <ListItemText primary="Our Team" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/feedback/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <FeedbackOutlined />
            </ListItemIcon>
            <ListItemText primary="Write feedback" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/tnc/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <GavelOutlined />
            </ListItemIcon>
            <ListItemText primary="Terms of Use" />
          </ListItem>
          <ListItem
            button
            component="a"
            href="/privacypolicy/"
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
              <SecurityOutlined />
            </ListItemIcon>
            <ListItemText primary="Privacy Policy" />
          </ListItem>
        </List>
      </Box>
      <Box
        sx={{
          width: 300,
          // height: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
        className={classes.sideBar}
      >
        <List className={classes.sidebarEnd}>
          <ListItem button>
            <ListItemIcon>
              <DevicesOutlined />
            </ListItemIcon>
            <ListItemText
              primary="Kunsatath Tech"
              secondary="This site is developed and actively maintained by"
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default MyDrawer;
