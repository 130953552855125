import React from "react";
import { AppBar } from "@material-ui/core";
import { CssBaseline, Toolbar, Box } from "@material-ui/core";

import { MenuOpenRounded, WhatsApp } from "@material-ui/icons";

import useStyles from "./styles";

import logo_light from "../images/logo-light.svg";
import logo_dark from "../images/logo-dark.svg";
import MyDrawer from "./MyDrawer.jsx/MyDrawer";

function BarAndNav({ drawer, setDrawer, darkMode }) {
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawer(open);
  };

  const classes = useStyles();
  return (
    <>
      <AppBar position="sticky" className={classes.appbar}>
        <Toolbar className={classes.appbarToolbar}>
          <MenuOpenRounded
            onClick={toggleDrawer(true)}
            className={classes.appbarBurger}
            style={{ fontSize: 30 }}
          />
          <Box className={classes.appbarMid}>
            <a href="/home/">
              <img
                src={darkMode ? logo_dark : logo_light}
                alt="LOGO of Valor Tutors advanced tutors SRINAGAR KASHMIR"
                height={40}
              />
            </a>
          </Box>
          <WhatsApp
            style={{ fontSize: 30 }}
            onClick={() =>
              window.open("https://api.whatsapp.com/send?phone=919796300767")
            }
          />
        </Toolbar>
      </AppBar>

      <MyDrawer drawer={drawer} toggleDrawer={toggleDrawer} />
      <CssBaseline />
    </>
  );
}

export default BarAndNav;
