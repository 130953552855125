import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    gridItem: {
        padding: 7
    },
    footer: {
        // position: "relative",
        // bottom: 0,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        padding: "30px 0"
    },
    footerLink: {
        textDecoration: "none",
        color: theme.palette.text.primary
    }
}));

export default useStyles