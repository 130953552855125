import React, { useState, Suspense, lazy } from "react";

import { CssBaseline, Box } from "@material-ui/core";

import {
  responsiveFontSizes,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";

import { CircularProgress } from "@mui/material";

import { Brightness4Outlined, Brightness5Outlined } from "@material-ui/icons";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// COMMON COMPONENTS
import BarAndNav from "./components/BarAndNav/BarAndNav";
import Footer from "./components/Footer/Footer";

// HOMEPAGE
import HomePage from "./components/HomePage/HomePage";

// MISC COMPONENTS WITH LAZY
const TermsAndConditions = lazy(() =>
  import("./components/TermsAndConditions/TermsAndConditions")
);
const PrivacyPolicy = lazy(() =>
  import("./components/PrivacyPolicy/PrivacyPolicy")
);
const About = lazy(() => import("./components/About/About"));
const Feedback = lazy(() => import("./components/Feedback/Feedback"));

// STUDENTS COMPONENTS WITH LAZY
const NewStudent = lazy(() => import("./components/Students/New/New"));
const InfoStudent = lazy(() => import("./components/Students/Info/Info"));
const Reviews = lazy(() => import("./components/Students/Reviews/Reviews"));

// TEACHER COMPONENTS WITH LAZY
const NewEducator = lazy(() => import("./components/Educators/New/New"));
const InfoEducator = lazy(() => import("./components/Educators/Info/Info"));
const Founders = lazy(() => import("./components/Educators/Founders/Founders"));

function App() {
  const [sb, setSb] = useState(false);

  const [darkMode, setDarkMode] = useState(
    !window.localStorage.getItem("darkMode") ||
      window.localStorage.getItem("darkMode") === "true"
      ? true
      : false
  );

  let theme = createTheme({
    palette: !darkMode
      ? {
          type: "light",
          primary: {
            main: "#26daab",
          },
          secondary: {
            main: "#f57f17",
          },
        }
      : {
          type: "dark",
          primary: {
            main: "#7cff4d",
            contrastText: "rgba(32,2,2,0.87)",
          },
          secondary: {
            main: "#9be7ff",
          },
          info: {
            main: "#2962ff",
          },
          success: {
            main: "#4bdeff",
          },
          divider: "rgba(181,181,181,0.12)",
          background: {
            paper: "#1e1e1e",
            default: "#353535",
          },
        },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: "5px",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#999",
          },
          "*::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
          MuiDialog: {
            paper: {
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
            },
          },
        },
      },
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BarAndNav drawer={sb} setDrawer={setSb} darkMode={darkMode} />

      <Box
        style={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
        }}
        onClick={() => {
          window.localStorage.setItem("darkMode", darkMode ? "false" : "true");
          setDarkMode(!darkMode);
        }}
      >
        {!darkMode ? (
          <Brightness5Outlined style={{ fontSize: 30 }} />
        ) : (
          <Brightness4Outlined style={{ fontSize: 30 }} />
        )}
      </Box>

      <BrowserRouter>
        <Suspense
          fallback={
            <Box
              display="flex"
              flexDirection={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            >
              <CircularProgress />
            </Box>
          }
        >
          <Routes>
            <Route path="/">
              <Route path="" element={<Navigate replace to="/home/" />} />
              <Route path="home" element={<HomePage setSb={setSb} />} />
              <Route path="tnc" element={<TermsAndConditions />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />
              <Route path="about" element={<About />} />
              <Route path="feedback" element={<Feedback />} />
            </Route>

            <Route path="/student">
              <Route path="" element={<Navigate replace to="/home/" />} />
              <Route path="info" element={<InfoStudent />} />
              <Route path="new" element={<NewStudent />} />
              <Route path="reviews" element={<Reviews />} />
            </Route>

            <Route path="/educator">
              <Route path="" element={<Navigate replace to="/home/" />} />
              <Route path="info" element={<InfoEducator />} />
              <Route path="new" element={<NewEducator />} />
              <Route path="founders" element={<Founders />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
