import React, { useEffect } from "react";

import { Divider } from "@material-ui/core";

import Header from "./Header/Header";
import Content from "./Content/Content";

const HomePage = ({ setSb }) => {
  useEffect(() => {
    document.title = "Home - Valor Tutors";
  }, []);

  return (
    <>
      <Header setSb={setSb} />
      <Content />
      <Divider />
    </>
  );
};

export default HomePage;
